@use 'src/assets/scss/core/variables' as var;
@use 'src/assets/scss/core/mixins' as mix;

.root {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  padding: 56px 0;

  background: var.$gray-70;
}

.title {
  @include mix.TitleHero;
  @include mix.media(
    (
      md: (
        font-size: 56px,
        line-height: 64px,
      ),
    )
  );

  margin-bottom: 8px;
  color: var.$green-main-100;
}

.subtitle {
  @include mix.TitleH4;
  @include mix.media(
    (
      md: (
        font-size: 20px,
        line-height: 28px,
        margin-bottom: 20px,
      ),
    )
  );

  margin-bottom: 32px;
  color: var.$green-main-100;
  text-align: center;
}

.description {
  @include mix.TextB1Regular;

  margin: 0;
  font-weight: 600;
  text-align: center;
}

.list {
  @include mix.media(
    (
      md: (
        margin: 20px auto 0,
      ),
    )
  );

  margin: 16px auto 0;
  padding: 0;
  list-style-type: none;
}

.item {
  display: flex;
  gap: 4px;
  justify-content: center;
  margin: 0 0 12px;
}

.link {
  @include mix.TextB1Regular;

  display: block;
  text-align: center;
  text-decoration: none;
  transition: 0.3s ease-in-out color;

  &:hover {
    color: var.$green-main-100;
  }
}
